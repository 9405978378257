import { SOCKET_REMOTE } from "../constants";
import {
  addSocket,
  addAccessCode,
  addJoinError,
} from "../redux/actions/usernameActions";
import { loadGameInfos } from "../redux/actions/gameActions";
import { push } from "connected-react-router";
import { GameInfos } from "../model/GameInfos";
import { Dispatch } from "react";
import io from "socket.io-client";

export const joinGame = (dispatch: Dispatch<any>, accessCode: string) => (
  promotion: number,
  accessCode: string,
  username?: string
) => () => {
  if (!username) {
    return;
  }

  const socket = io(SOCKET_REMOTE + promotion.toString());
  socket.emit("join", { username: username, accessCode: accessCode });
  console.log("Join sent with name: " + username);

  // On accept
  socket.on("accepted", (gameInfos: GameInfos) => {
    console.log("You joined the server");
    dispatch(addSocket(socket));
    dispatch(addAccessCode(accessCode));
    dispatch(loadGameInfos(gameInfos));
    dispatch(push("/game"));
  });

  socket.on("rejected", (error: string) => {
    dispatch(addJoinError(error));
  });
};
