import React, { useState } from "react";

import "../style/pure-min.css";
import "../style/styleBlog.scss";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { addUsername } from "../redux/actions/usernameActions";

function AccessPage() {
  const [username, setUsername] = useState<string>("Anonymous");
  const dispatch = useDispatch();

  function goToPromoPage() {
    dispatch(addUsername(username));
    dispatch(push("/promo"));
  }

  return (
    <div className="App pure-g fullPageContainer">
      <header className="pure-u-1">
        <h1>IKY2 Reborn</h1>
      </header>
      <div className="pure-u-1 fullPageContent">
        <form className="pure-form pure-form-stacked" onSubmit={goToPromoPage}>
          <fieldset>
            <input
              id="nickname"
              type="text"
              placeholder="Surnom en jeu"
              className="center pure-input-1-2"
              autoComplete="off"
              onChange={(e) => setUsername(e.target.value)}
            />

            <button
              type="submit"
              className="center pure-button pure-button-primary pure-input-1-2"
              style={{ marginTop: "0.5em" }}
            >
              Accéder à la matrice
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default AccessPage;
