import { createStore, applyMiddleware, CombinedState } from "redux";
import { routerMiddleware, RouterState } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { History } from "history";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
import { IkyStore } from "./ikyReducer";

export default function configureStore(initialState: object, history: History) {
  let middlewares = [routerMiddleware(history), thunk];
  if (process.env.NODE_ENV !== "production") {
    middlewares = [...middlewares];
  }

  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept("./reducer", () => {
      const nextRootReducer = rootReducer(history);
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export type ReduxFullState = CombinedState<{
  ikyReducer: IkyStore;
  router: RouterState<unknown>;
}>;
