import {
  ADD_USERNAME,
  ADD_SOCKET,
  ADD_MESSAGE,
  ADD_ACCESS_CODE,
  ADD_JOIN_ERROR,
} from "./actions/usernameActions";
import { Message } from "../model/Message";
import { GameInfos } from "../model/GameInfos";
import { LOAD_GAME, UPDATE_PLAYERS, UPDATE_TIMER } from "./actions/gameActions";
import { Player } from "../model/Player";

export type TimerInfos = { serverStart: Date; serverEnd: Date };

export type IkyStore = {
  username?: string;
  accessCode?: string;
  socket?: SocketIOClient.Socket;
  messages: Message[];
  gameInfos?: GameInfos;
  joinError?: string;
  timer: TimerInfos;
};

//const socket = io(SOCKET_REMOTE + "50");
//socket.emit("join", { username: "Michel", accessCode: "Sivouplé" });
//socket.on("accepted", (gameInfos: GameInfos) => {
//  console.log("You joined the server");
//});

const defaultState: IkyStore = {
  messages: [],
  timer: {
    serverStart: new Date(),
    serverEnd: new Date(new Date().getTime() + 10000),
  },
};

export function ikyReducer(
  initialState: IkyStore = defaultState,
  action: {
    type: string;
    username?: string;
    socket?: SocketIOClient.Socket;
    message?: Message;
    gameInfos?: GameInfos;
    players?: Player[];
    accessCode?: string;
    joinError?: string;
    phase?: string;
    timerInfos?: TimerInfos;
  }
) {
  switch (action.type) {
    case ADD_USERNAME:
      return Object.assign({}, initialState, { username: action.username });
    case ADD_SOCKET:
      return Object.assign({}, initialState, { socket: action.socket });
    case ADD_ACCESS_CODE:
      return Object.assign({}, initialState, { accessCode: action.accessCode });
    case ADD_JOIN_ERROR:
      return Object.assign({}, initialState, { joinError: action.joinError });
    case ADD_MESSAGE:
      return Object.assign({}, initialState, {
        messages: [...initialState.messages, action.message!],
      });
    case LOAD_GAME:
      return Object.assign({}, initialState, { gameInfos: action.gameInfos });
    case UPDATE_PLAYERS:
      if (!initialState.gameInfos) {
        return initialState;
      }
      const newGamesInfos = Object.assign({}, initialState.gameInfos, {
        players: action.players,
      });
      return Object.assign({}, initialState, {
        gameInfos: newGamesInfos,
      });
    case UPDATE_TIMER:
      return Object.assign({}, initialState, { timer: action.timerInfos! });
    default:
      return initialState;
  }
}
