import React from "react";

import "../style/pure-min.css";
import "../style/styleBlog.scss";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";

const RulesBlock = styled.div`
  text-align: left;
  padding-left: 3vw;
  padding-right: 3vw;
`;

export function RulePage() {
  const dispatch = useDispatch();

  function goToMainPage() {
    dispatch(push("/"));
  }

  return (
    <div className="App pure-g fullPageContainer">
      <header className="pure-u-1">
        <h2>IKY2 Reborn</h2>
      </header>
      <div className="pure-u-1 fullPageContent">
        <button
          type="submit"
          className="center pure-button pure-button-primary pure-input-1-2"
          style={{ marginTop: "0.5em", fontSize: "80%" }}
          onClick={goToMainPage}
        >
          Retour au choix de la promotion
        </button>
        <RulesBlock>
          <h3>Règles</h3>
          <p>
            Le jeu se joue désormais en <strong>multijoueurs</strong> divisés en
            deux étapes:
          </p>
          <ol>
            <li>
              Chaque joueur connecté doit rentrer le nom de la personne à
              l'écran
            </li>
            <li>
              Si le jeu ne connaissait pas la bonne réponse, chacun peut valider
              les réponses des autres joueurs
            </li>
          </ol>
        </RulesBlock>
        <RulesBlock>
          <h3>Les points</h3>
          <p>
            Les points sont gagnés comme suit :
            <ul>
              <li>1 point pour le prénom correct</li>
              <li>1 point pour le nom correct</li>
              <li>1 point bonus pour les réponses juste consécutive (🔥)</li>
              <br />
              <li>
                Des points pour la vitesse de réponse :
                <ul>
                  <li>
                    <strong>Si moins de 5 joueurs :</strong> 1 point bonus pour
                    le premier à avoir répondu (🥇)
                  </li>
                  <li>
                    <strong>Si plus de 5 joueurs :</strong> 2 point bonus pour
                    le premier à avoir répondu (🥇) et 1 pour les deux suivants
                    (🥈 et 🥉){" "}
                  </li>
                </ul>
              </li>
            </ul>
          </p>
        </RulesBlock>
        <br />
        <RulesBlock>
          <h3>Phase de validation</h3>
          <p>
            Cette nouvelle version d'IKY ne contient au départ pas les réponses.
            <br />
            Après certaines phases de réponses, vous aurez donc à valider les
            réponses des autres joueurs. Ce faisant, vous remplissez la base de
            connaissance et, au fur et à mesure, le jeu devient de plus en plus
            automatique.
          </p>
        </RulesBlock>
        <RulesBlock>
          <h3>Le bouton "Pas dans la promo"</h3>
          <p>
            Le jeu d'images contient également les doctorants arrivés en
            2012/2013, si un certain nombre de joueurs clique sur le bouton "Pas
            dans la promo" alors l'image en question ne pourra plus réapparaitre
          </p>
        </RulesBlock>
      </div>
    </div>
  );
}
