import { Message } from "../../model/Message";

const ADD_USERNAME = "ADD_USERNAME";
const ADD_SOCKET = "ADD_SOCKET";
const ADD_MESSAGE = "ADD_MESSAGE";
const ADD_ACCESS_CODE = "ADD_ACCESS_CODE";
const ADD_JOIN_ERROR = "ADD_JOIN_ERROR";

function addUsername(username: string) {
  return { type: ADD_USERNAME, username };
}

function addAccessCode(accessCode: string) {
  return { type: ADD_ACCESS_CODE, accessCode: accessCode };
}

function addSocket(socket: SocketIOClient.Socket) {
  return { type: ADD_SOCKET, socket };
}

function addMessage(message: Message) {
  return { type: ADD_MESSAGE, message };
}

function addJoinError(joinError: string) {
  return { type: ADD_MESSAGE, joinError: joinError };
}

export {
  ADD_USERNAME,
  addUsername,
  ADD_SOCKET,
  addSocket,
  ADD_MESSAGE,
  addMessage,
  ADD_ACCESS_CODE,
  addAccessCode,
  ADD_JOIN_ERROR,
  addJoinError,
};
