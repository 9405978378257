import React, { useState, useEffect } from "react";
import { ReduxFullState } from "../redux/store";
import { useSelector } from "react-redux";
import { format } from "util";

export function TimerBar() {
  const serverTimer = useSelector(
    (state: ReduxFullState) => state.ikyReducer.timer
  );

  const [localCurrent, setLocalCurrent] = useState(new Date());

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLocalCurrent(new Date());
    }, 40);

    return () => clearTimeout(timeout);
  });

  if (!serverTimer) {
    return <></>;
  }

  let percent =
    (+localCurrent - +serverTimer.serverStart) /
    (+serverTimer.serverEnd - +serverTimer.serverStart);
  if (percent < 0 || percent > 1) {
    percent = 1;
  }

  const c = percent * 255; // Percentage of color
  const color = format("rgb(%s,%s,0)", c, 255 - c);

  const percentInt = (100 * percent).toFixed(2);

  return (
    <div
      style={{
        position: "relative",
        height: "1vh",
        background: color,
        width: percentInt + "%",
      }}
    ></div>
  );
}
