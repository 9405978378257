import React, { useState, FormEvent } from "react";
import { SOCKET_REMOTE } from "../constants";
import { useSelector } from "react-redux";
import { ReduxFullState } from "../redux/store";
import styled from "styled-components";
import { Answer } from "../model/Player";
import { TimerBar } from "./TimerBar";

export const ForcedCenter = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const GameImage = styled.img`
  width: 50vw;
  height: 50vh;
  object-fit: contain;
`;

export function AnswerForm() {
  const socket = useSelector(
    (state: ReduxFullState) => state.ikyReducer.socket!
  );

  const roundNb = useSelector(
    (state: ReduxFullState) => state.ikyReducer.gameInfos!.currentRound
  );

  console.log("Displaying for " + roundNb);

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [answered, isAnswered] = useState<boolean>(false);
  const [answeredRound, setAnsweredRound] = useState<number>(-1);

  const lastnameFieldRef = React.useRef<HTMLInputElement>(null);
  const firstnameFieldRef = React.useRef<HTMLInputElement>(null);

  if (answered && answeredRound !== roundNb) {
    isAnswered(false);
    setLastname("");
    setFirstname("");
    firstnameFieldRef.current?.focus();
  }

  function submitAnswer(e: FormEvent) {
    const answer: Answer = { firstname: firstname, lastname: lastname };
    socket.emit("answer", answer);
    isAnswered(true);
    setAnsweredRound(roundNb);
    e.preventDefault();
  }

  const lastnameField = (
    <input
      className="pure-input-1"
      type="text"
      placeholder="Nom"
      disabled={answered}
      value={lastname}
      onChange={(e) => {
        const value = e.target.value;
        setLastname(value);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 8 && lastname.length === 0) {
          e.stopPropagation();
          e.preventDefault();
          firstnameFieldRef.current?.focus();
        }
      }}
      ref={lastnameFieldRef}
    />
  );

  const firstnameField = (
    <input
      className="pure-input-1"
      type="text"
      placeholder="Prénom"
      disabled={answered}
      autoFocus={true}
      value={firstname}
      onChange={(e) => {
        const value = e.target.value;
        setFirstname(value);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 32) {
          e.stopPropagation();
          e.preventDefault();
          lastnameFieldRef.current?.focus();
        }
      }}
      ref={firstnameFieldRef}
    ></input>
  );

  return (
    <form
      className="pure-form pure-g"
      style={{ width: "80%", margin: "auto" }}
      action="#"
      onSubmit={submitAnswer}
    >
      <h1 className="pure-u-1">
        {answered ? "Réponse envoyée" : "Qui est-ce ?"}
      </h1>
      <div className="pure-u-2-5">{firstnameField}</div>
      <div className="pure-u-2-5">{lastnameField}</div>
      <div className="pure-u-1-5">
        <button
          className="pure-input-1 pure-button"
          type="submit"
          disabled={answered}
        >
          ►
        </button>
      </div>
    </form>
  );
}

export function GamePanel(props: { className?: string }) {
  const roomUrl = useSelector(
    (state: ReduxFullState) => state.ikyReducer.gameInfos!.imagePath
  );

  const accessCode = useSelector(
    (state: ReduxFullState) => state.ikyReducer.accessCode!
  );

  const imgUrl =
    SOCKET_REMOTE +
    roomUrl +
    "current?accessCode=" +
    accessCode +
    "&" +
    performance.now();

  return (
    <div className={props.className}>
      <TimerBar></TimerBar>
      <ForcedCenter className="hugeOnSmall">
        <GameImage src={imgUrl} />
        <AnswerForm />
      </ForcedCenter>
    </div>
  );
}
