import React, { useState } from "react";
import { SOCKET_REMOTE } from "../constants";
import { useSelector } from "react-redux";
import { ReduxFullState } from "../redux/store";
import styled from "styled-components";
import { Answer, ValidationSocketModel } from "../model/Player";
import { TimerBar } from "./TimerBar";
import { ForcedCenter } from "./GamePanel";

const ValidationImage = styled.img`
  width: 25vw;
  height: 25vh;
  object-fit: contain;
`;

const AnswerText = styled.td`
  text-transform: capitalize;
  font-weight: bold;
`;

const HeaderText = styled.th`
  text-transform: capitalize;
  font-variant: small-caps;
  font-weight: bold;
  text-align: center;
  width: 20%;
`;

export function ValidationForm(props: {
  playerId: string;
  username: string;
  answer: Answer;
}) {
  const socket = useSelector(
    (state: ReduxFullState) => state.ikyReducer.socket!
  );

  const [firstnameAnswered, setFirstnameAnswered] = useState<boolean>(false);
  const [lastnameAnswered, setLastnameAnswered] = useState<boolean>(false);

  function submitValidation(refersTo: string, isCorrect: boolean) {
    const validation: ValidationSocketModel = {
      playerId: props.playerId,
      refersTo: refersTo,
      isCorrect: isCorrect,
    };
    console.log(validation);
    socket.emit("validation", validation);

    if (refersTo === "lastname") {
      setLastnameAnswered(true);
    } else {
      setFirstnameAnswered(true);
    }
  }

  function ButtonGroup(isAnswered: boolean, target: string) {
    return (
      <td>
        <button
          className="pure-button button-success"
          type="submit"
          disabled={isAnswered}
          onClick={() => submitValidation(target, true)}
        >
          OK
        </button>
        <button
          className="pure-button button-error"
          type="submit"
          disabled={isAnswered}
          onClick={() => submitValidation(target, false)}
        >
          KO
        </button>
      </td>
    );
  }

  return (
    <tr>
      <td>{props.username}</td>
      <AnswerText>
        {props.answer.firstname ? props.answer.firstname : <i>NON</i>}
      </AnswerText>
      <AnswerText>
        {props.answer.lastname ? props.answer.lastname : <i>NON</i>}
      </AnswerText>

      {ButtonGroup(firstnameAnswered, "firstname")}
      {ButtonGroup(lastnameAnswered, "lastname")}
    </tr>
  );
}

export function ValidationPanel(props: { className?: string }) {
  const roomUrl = useSelector(
    (state: ReduxFullState) => state.ikyReducer.gameInfos!.imagePath
  );

  const socket = useSelector(
    (state: ReduxFullState) => state.ikyReducer.socket!
  );

  const accessCode = useSelector(
    (state: ReduxFullState) => state.ikyReducer.accessCode!
  );

  const players = useSelector(
    (state: ReduxFullState) => state.ikyReducer.gameInfos!.players
  ).filter(
    (player) =>
      player.id !== socket.id &&
      (player.previousAnswer.firstname || player.previousAnswer.lastname)
  );

  const [hasAnswered, setHasAnswered] = useState<boolean>(false);

  const imgUrl =
    SOCKET_REMOTE +
    roomUrl +
    "current?accessCode=" +
    accessCode +
    "&" +
    performance.now();

  function endValidation() {
    socket.emit("endValidation");
    setHasAnswered(true);
  }

  function endValidationUnknown() {
    socket.emit("endValidationUnknown");
    setHasAnswered(true);
  }

  if (hasAnswered) {
    return (
      <div className={props.className}>
        <TimerBar></TimerBar>
        <ForcedCenter className="hugeOnSmall">
          <h1>En attente des autres validations</h1>
        </ForcedCenter>
      </div>
    );
  } else {
    return (
      <div className={props.className}>
        <TimerBar></TimerBar>
        <div style={{ paddingTop: "5vh" }}>
          <ValidationImage src={imgUrl} />
          <table
            className="pure-table pure-table-horizontal"
            style={{ width: "85%", margin: "auto", border: "0" }}
          >
            <thead style={{ backgroundColor: "#e0e0e0bb" }}>
              <tr>
                <HeaderText>Joueur</HeaderText>
                <HeaderText>Prénom Proposé</HeaderText>
                <HeaderText>Nom Proposé</HeaderText>
                <HeaderText>Validation Prénom</HeaderText>
                <HeaderText>Validation Nom</HeaderText>
              </tr>
            </thead>
            <tbody>
              {players.map((player) => {
                return (
                  <ValidationForm
                    key={player.id}
                    username={player.name}
                    playerId={player.id}
                    answer={player.previousAnswer}
                  />
                );
              })}
            </tbody>
          </table>
          <button
            className="pure-input-1 pure-button"
            type="submit"
            onClick={endValidation}
          >
            Finir la validation
          </button>
          <br></br>
          <button
            className="pure-input-1 pure-button button-mini button-error"
            type="submit"
            onClick={endValidationUnknown}
          >
            Pas dans la promo
          </button>
        </div>
      </div>
    );
  }
}
