import React from "react";
import { Player } from "../model/Player";
import { GameInfos } from "../model/GameInfos";
import styled from "styled-components";

const discordLink = "GREfwta";
const discordRoot = "https://discord.gg/";

const AnswerBlock = styled.p`
  text-transform: capitalize;
  font-style: italic;
  font-size: 80%;
`;

function PlayerInfos(props: { player: Player }) {
  var flames = [];
  for (var i = 0; i < props.player.serie; i++) {
    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    flames.push("🔥");
  }
  return (
    <>
      <p>
        {props.player.name}
        {flames.map((flame) => {
          return flame;
        })}
        {props.player.bonus ? props.player.bonus : ""}
        <i style={{ fontSize: "80%" }}> {props.player.points} pts</i>
      </p>
      {props.player.previousAnswer &&
      (props.player.previousAnswer.firstname ||
        props.player.previousAnswer.lastname) ? (
        <AnswerBlock>
          ↪{" "}
          {props.player.previousAnswer.firstname
            ? props.player.previousAnswer.firstname + " "
            : ""}
          {props.player.previousAnswer.lastname
            ? props.player.previousAnswer.lastname
            : ""}
        </AnswerBlock>
      ) : null}
    </>
  );
}

function LeftPanel(props: {
  className?: string;
  game: GameInfos;
  hideTitle?: boolean;
}) {
  return (
    <div className={props.className}>
      {!props.hideTitle ? (
        <h3
          className="title-font"
          style={{ marginTop: ".5em", marginBottom: "1em", fontSize: "150%" }}
        >
          IKY2 Reborn
        </h3>
      ) : null}

      {props.game.currentRound <= props.game.maxRound ? (
        <p>
          Manche <b>{props.game.currentRound}</b> sur {props.game.maxRound}
        </p>
      ) : (
        <p>Résultats</p>
      )}

      <h4
        className="title-font"
        style={{
          marginTop: "0.5em",
          marginBottom: ".3em",
          fontSize: "80%",
          backgroundColor: "aliceblue",
          padding: "0.8em 0em",
        }}
      >
        Viens discuter sur le{" "}
        <a
          href={discordRoot + discordLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Discord
        </a>
      </h4>

      <h4
        className="title-font"
        style={{ marginTop: "0.5em", marginBottom: ".3em" }}
      >
        Joueurs
      </h4>
      <div style={{ textAlign: "left", paddingLeft: ".5em" }}>
        {props.game.players.map((player) => {
          return (
            <PlayerInfos
              key={performance.now() + Math.random()}
              player={player}
            />
          );
        })}
      </div>
    </div>
  );
}

export default LeftPanel;
