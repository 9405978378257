import { useDispatch } from "react-redux";
import { addMessage } from "./actions/usernameActions";
import { Message } from "../model/Message";
import {
  updatePlayers,
  loadGameInfos,
  updateTimer,
} from "./actions/gameActions";
import { GameInfos } from "../model/GameInfos";
import { Player } from "../model/Player";
import { TimerInfos } from "./ikyReducer";
import { format } from "util";

export function useNewSocketManager(socket?: SocketIOClient.Socket) {
  const dispatch = useDispatch();

  if (socket) {
    console.log("New socket manager");

    socket.removeAllListeners();

    socket.on("broadcast", (broadcast: string) => {
      dispatch(addMessage({ content: broadcast }));
    });
    socket.on("message", (message: Message) => {
      console.log("Receiving");
      dispatch(addMessage(message));
    });
    // When new player join
    socket.on("updatePlayers", (players: Player[]) => {
      dispatch(updatePlayers(players));
    });

    //Validate room
    socket.on("validateRound", (gameInfos: GameInfos) => {
      console.log("New validation");
      dispatch(loadGameInfos(gameInfos));
    });

    socket.on("startRound", (gameInfos: GameInfos) => {
      console.log("New round");
      dispatch(loadGameInfos(gameInfos));
    });

    socket.on("result", (gameInfos: GameInfos) => {
      console.log("Display result");
      dispatch(loadGameInfos(gameInfos));
    });

    socket.on(
      "updateTimer",
      (t: { serverStart: string; serverEnd: string }) => {
        console.log(format("Timer: %s -> %s", t.serverStart, t.serverEnd));
        const timerInfos: TimerInfos = {
          serverStart: new Date(t.serverStart),
          serverEnd: new Date(t.serverEnd),
        };
        dispatch(updateTimer(timerInfos));
      }
    );
  }
}
