import * as React from "react";
import { Route, Switch } from "react-router-dom";
import AccessPage from "./pages/AccessPage";
import PromoPage from "./pages/PromoPage";
import GamePage from "./pages/GamePage";
import { createBrowserHistory } from "history";
import configureStore, { ReduxFullState } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { useNewSocketManager } from "./redux/socketManagement";
import { RulePage } from "./pages/RulePage";

const initialState = {};
export const history = createBrowserHistory();
const store = configureStore(initialState, history);

export const HasUsername: React.FC = (props) => {
  const username = useSelector(
    (state: ReduxFullState) => state.ikyReducer.username
  );
  if (!username) return <AccessPage />;
  return <div className="full-height">{props.children}</div>;
};

export const SocketProvider: React.FC = (props) => {
  const socket = useSelector(
    (state: ReduxFullState) => state.ikyReducer.socket
  );

  useNewSocketManager(socket);
  return <div className="full-height">{props.children}</div>;
};

export const AppRouter: React.FunctionComponent = () => {
  console.log(store.getState());
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SocketProvider>
          <HasUsername>
            <Switch>
              <Route exact={true} path="/" component={AccessPage} />
              <Route path="/rules" component={RulePage} />
              <Route exact path="/promo" component={PromoPage} />
              <Route path="/game" component={GamePage} />
            </Switch>
          </HasUsername>
        </SocketProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default AppRouter;
