import React, { useState } from "react";

import "../style/pure-min.css";
import "../style/styleBlog.scss";
import { ReduxFullState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { joinGame } from "../functions/JoinGame";
import { push } from "connected-react-router";

function PromoPage() {
  const username = useSelector(
    (state: ReduxFullState) => state.ikyReducer.username
  );
  const joinError = useSelector(
    (state: ReduxFullState) => state.ikyReducer.joinError
  );
  const currentReduxSocket = useSelector(
    (state: ReduxFullState) => state.ikyReducer.socket
  );
  const [accessCode, setAccessCode] = useState<string>("code");

  const dispatch = useDispatch();
  const joinFunction = joinGame(dispatch, accessCode);

  function goToRulesPage() {
    dispatch(push("/rules"));
  }

  if (currentReduxSocket) {
    dispatch(push("/game"));
  }

  const phrase = joinError
    ? joinError
    : "Entre le code et choisis ta promotion";

  return (
    <div className="App pure-g fullPageContainer">
      <header className="pure-u-1">
        <h1>IKY2 Reborn</h1>
      </header>
      <div className="pure-u-1 fullPageContent">
        <h2>{phrase}</h2>
        <div className="pure-form">
          <fieldset>
            <input
              id="accessCode"
              type="text"
              placeholder="Code d'accès"
              className="center pure-input-1-3"
              onChange={(e) => setAccessCode(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <button
              type="submit"
              className="pure-button pure-button-primary pure-input-1-10"
              style={{ marginTop: "0.5em" }}
              onClick={joinFunction(50, accessCode, username)}
            >
              Promotion 50
            </button>
          </fieldset>
        </div>
        <button
          type="submit"
          className="center pure-button pure-button-primary pure-input-1-2"
          style={{ marginTop: "0.5em", fontSize: "80%" }}
          onClick={goToRulesPage}
        >
          Voir les règles
        </button>
      </div>
    </div>
  );
}

export default PromoPage;
