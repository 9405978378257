import React, { useState } from "react";

import "../style/pure-min.css";
import "../style/pureResponsiveGrid.css";
import "../style/styleBlog.scss";
import LeftPanel from "../components/LeftPanel";
import ChatPanel from "../components/ChatPanel";
import { ReduxFullState } from "../redux/store";
import { useSelector } from "react-redux";
import { GamePanel } from "../components/GamePanel";
import { ValidationPanel } from "../components/ValidationPanel";
import { TimerBar } from "../components/TimerBar";

export const Phases = {
  EMPTY: "empty",
  VALIDATING: "validating",
  ANSWERING: "answering",
};

function GamePage() {
  const gameInfos = useSelector(
    (state: ReduxFullState) => state.ikyReducer.gameInfos
  );

  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [showLeftModal, setShowLeftModal] = useState<boolean>(false);

  if (!gameInfos) {
    return <div>Chargement de la partie</div>;
  }

  const leftPanelLarge = (
    <LeftPanel
      className="sm-hide pure-u-lg-1-5 full-height background-left"
      game={gameInfos}
    />
  );

  const leftPanelSmall = (
    <div className="sm-show topLeft">
      <p
        style={{ textAlign: "left" }}
        onClick={() => setShowLeftModal(!showLeftModal)}
      >
        SCORE
      </p>
      {showLeftModal ? (
        <LeftPanel
          className="background-left-sm"
          game={gameInfos}
          hideTitle={true}
        />
      ) : null}
    </div>
  );

  const chatPanelLarge = (
    <ChatPanel className="background-right sm-hide pure-u-lg-1-5 chatContainer"></ChatPanel>
  );

  const chatPanelSmall = (
    <>
      <div className="sm-show topRight">
        <p onClick={() => setShowChatModal(true)}>{"Chat>>"}</p>
      </div>
      {showChatModal ? (
        <div className="sm-show stuckRight">
          <div className="background-right-sm">
            <p
              style={{ textAlign: "left", marginTop: "3vg" }}
              onClick={() => setShowChatModal(false)}
            >
              {"<<Game"}
            </p>
            <ChatPanel className="chatContainer"></ChatPanel>
          </div>
        </div>
      ) : null}
    </>
  );

  return (
    <div className="App pure-g full-height" style={{ position: "relative" }}>
      {leftPanelLarge}
      {leftPanelSmall}
      {gameInfos.phase === Phases.ANSWERING ? (
        <GamePanel className="pure-u-1 pure-u-lg-3-5 full-height background-center" />
      ) : gameInfos.phase === Phases.VALIDATING ? (
        <ValidationPanel className="pure-u-1 pure-u-lg-3-5 full-height background-center" />
      ) : (
        <div className="pure-u-1 pure-u-lg-3-5 full-height background-center">
          <TimerBar></TimerBar>
          <h2 className="title-font" style={{ paddingTop: "5vh" }}>
            Prochaine partie dans 30 secondes
          </h2>
        </div>
      )}
      {chatPanelLarge}
      {chatPanelSmall}
    </div>
  );
}

export default GamePage;
