import { combineReducers } from "redux";
import { RouterState, connectRouter } from "connected-react-router";
import { History } from "history";
import { ikyReducer, IkyStore } from "./ikyReducer";

const rootReducer = (history: History) =>
  combineReducers({
    ikyReducer: ikyReducer,
    router: connectRouter(history),
  });
export interface State {
  ikyReducer: IkyStore;
  router: RouterState;
}
export default rootReducer;
