import React, { useState, FormEvent, useRef, useEffect } from "react";
import { ReduxFullState } from "../redux/store";
import { useSelector } from "react-redux";
import { Message } from "../model/Message";
import styled from "styled-components";

const MessageTileStyled = styled.p`
  text-align: left;
  padding: 0.2em 0em 0em 0em;
  margin: 0;
`;

const ServerMessageTileStyled = styled(MessageTileStyled)`
  font-weight: bold;
`;

const ChatContent = styled.div`
  padding: 1em 0.5em 0em 0.5em;
`;

function MessageTile(props: { className?: string; message: Message }) {
  if (props.message.from) {
    return (
      <MessageTileStyled>
        <span className="userName">{props.message.from} :</span>{" "}
        {props.message.content}
      </MessageTileStyled>
    );
  } else {
    return (
      <ServerMessageTileStyled>{props.message.content}</ServerMessageTileStyled>
    );
  }
}

function ChatPanel(props: {
  id?: string;
  className?: string;
  ref?:
    | string
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null;
}) {
  // Prepare to receive
  const messages = useSelector(
    (state: ReduxFullState) => state.ikyReducer.messages
  );
  const socket = useSelector(
    (state: ReduxFullState) => state.ikyReducer.socket
  )!;
  const messagesEndRef: any = useRef(null);

  // Prepare to emit
  const [message, setMessage] = useState<string>("");

  function sendMessage(e: FormEvent) {
    socket.emit("message", message);
    setMessage("");
    e.preventDefault();
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div id={props.id} className={props.className} ref={props.ref}>
      <ChatContent className="chatContent">
        {messages.map((message) => (
          <MessageTile message={message} />
        ))}
        <div ref={messagesEndRef} />
      </ChatContent>
      <form className="pure-form chatInput" action="#" onSubmit={sendMessage}>
        <fieldset className="pure-g">
          <input
            id="message"
            type="text"
            placeholder="Message"
            className="pure-input-3-4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            type="submit"
            className="pure-button pure-button-primary pure-input-1-4"
          >
            ►
          </button>
        </fieldset>
      </form>
    </div>
  );
}

export default ChatPanel;
