import { GameInfos } from "../../model/GameInfos";
import { Player } from "../../model/Player";
import { TimerInfos } from "../ikyReducer";

export const LOAD_GAME = "LOAD_GAME";
export function loadGameInfos(gameInfos: GameInfos) {
  return { type: LOAD_GAME, gameInfos };
}

export const UPDATE_PLAYERS = "UPDATE_PLAYERS";
export function updatePlayers(players: Player[]) {
  return { type: UPDATE_PLAYERS, players };
}

export const UPDATE_TIMER = "UPDATE_TIMER";
export function updateTimer(timerInfos: TimerInfos) {
  return { type: UPDATE_TIMER, timerInfos };
}
